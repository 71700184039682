<template>
  <div v-if="searchField" class="pt-2" aut-alphabet-search>
    <div v-if="displayAsSlide">
      <v-slide-group show-arrows multiple v-model="searchAlphabets">
        <v-slide-item>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="searchAlphabets.length == 0"
                v-bind="attrs"
                v-on="on"
                aut-alphabet-cancel
                icon
                @click="cancelAlphabeticSearch"
                :small="isTabletOrLarger"
              >
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </template>
            Clear
          </v-tooltip>
        </v-slide-item>
        <v-slide-item
          v-for="(alphabet, i) in alphabets"
          :key="i"
          :value="alphabet"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                aut-alphabet
                icon
                v-bind="attrs"
                v-on="on"
                :input-value="searchAlphabets.includes(alphabet)"
                @click="toggleAlphabet(alphabet)"
                :small="isTabletOrLarger"
              >
                {{ alphabet }}
              </v-btn>
            </template>
            Filter by names starting with {{ alphabet | upperFirst }}
          </v-tooltip>
        </v-slide-item>
      </v-slide-group>
    </div>
    <div v-else>
      <div class="d-flex flex-wrap justify-start">
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="searchAlphabets.length == 0"
                v-bind="attrs"
                v-on="on"
                aut-alphabet-cancel
                icon
                @click="cancelAlphabeticSearch"
                :small="isTabletOrLarger"
              >
                <v-icon>mdi-cancel</v-icon>
              </v-btn>
            </template>
            Clear
          </v-tooltip>
        </div>
        <div v-for="(alphabet, i) in alphabets" :key="i" :value="alphabet">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                aut-alphabet
                rounded
                icon
                v-bind="attrs"
                v-on="on"
                :input-value="searchAlphabets.includes(alphabet)"
                @click="toggleAlphabet(alphabet)"
                :small="isTabletOrLarger"
              >
                {{ alphabet }}
              </v-btn>
            </template>
            Filter by names starting with {{ alphabet | upperFirst }}
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { isAlphabeticSearch } from "../util";
const debug = require("debug")("atman.component.list.search.alphabetic_search"); // eslint-disable-line
const alphabets = "0123456789abcdefghijklmnopqrstuvwxyz".split("");

export default {
  name: "AlphabeticSearch",
  props: {
    criteria: {},
    definition: {},
  },
  created() {
    this.alphabets = alphabets;
  },
  computed: {
    searchAlphabets: {
      get() {
        const criteria = this.criteria || {};
        const field = this.searchField;
        const searchValues = criteria[field];

        if (isAlphabeticSearch(searchValues)) {
          return searchValues;
        }

        return [];
      },
      set(alphabets) {
        const searchResult = {};
        const field = this.searchField;

        if (!field || typeof field != "string") {
          return;
        }

        if (alphabets.length) {
          searchResult[field] = alphabets;
        }
        debug(`Emitting search result`, searchResult);
        this.$emit("search", searchResult);
      },
    },
    displayAsSlide() {
      return (
        this.isTabletOrSmaller ||
        this.definition?.alphabetic_search?.mode == "slide"
      );
    },
    searchField() {
      const definition = this.definition;
      debug("searchField", definition?.alphabetic_search?.field);
      return definition?.alphabetic_search?.field || "";
    },
  },
  methods: {
    toggleAlphabet(alphabet) {
      let searchAlphabets = [...this.searchAlphabets];
      if (searchAlphabets.includes(alphabet)) {
        searchAlphabets = searchAlphabets.filter((item) => item != alphabet);
      } else {
        searchAlphabets.push(alphabet);
      }
      this.searchAlphabets = searchAlphabets;
    },
    cancelAlphabeticSearch() {
      debug(`in cancelAlphabeticSearch`);
      this.$emit("search", "");
    },
  },
};
</script>
